<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 10000"
        @click.stop="hide"
      ></div>
      <div class="upload-resume">
        <div class="row-end">
          <img src="../assets/forget/15.png" class="pointer close-img" alt="" @click="hide" />
        </div>
        <div class="resume-center">
          <div class="resume-center-img direction">
            <img src="../assets/forget/14.png" alt="" />
            <div class="font-normal">上传我的简历</div>
          </div>
          <div class="upload-button bg-blue text-white font-normal flex-center pointer" @click="uploadCv">上传简历</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      status: false,
    };
  },

  created() {},

  mounted() {},

  methods: {
      show(){
          this.status=true
      },
      hide(){
          this.status=false
      },
      uploadCv(){
          this.status=false
          this.$emit('uploadCv')
      }
  },
};
</script>
<style lang='less' scoped>
.upload-resume {
  width: 322px;
  height: 401px;
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  padding: 11px 11px 36px 31px;
  .close-img {
    width: 11px;
    height: 11px;
  }
  .resume-center {
    width: 100%;
    padding-right: 9px;
    margin-top: 19px;
    .resume-center-img {
      height: 260px;
      border: 1px dashed #e3e7ed;
      width: 100%;
      color: #485066;
      justify-content: center;
      img {
        width: 7.1875rem;
        height: 6.875rem;
        margin-bottom: 1.4375rem;
      }
    }
    .upload-button{
        margin-top: 1.875rem;
        width: 100%;
        height: 2.125rem;
    }
  }
}
</style>