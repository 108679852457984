<template>
	<div class="el-resumeCv text-main font-normal">
		<div class="resumeCv-heard direction-between" v-if="annex_status !== 2">
			<div class="space-between resumeCv-heard-span">
				<span>附件管理</span>
				<span>{{ msgText }}</span>
			</div>
			<div class="bg-blue text-white font-weight-bolder flex-center pointer resumeCv-heard-button"
				@click="cvShow">
				上传简历
			</div>
		</div>
		<div class="resumeCv-heard direction-between" v-if="annex_status == 2">
			<div class="title-cv-text">你的简历</div>
			<div class="space-between resumeCv-heard-span">
				<div class="ellip text-ellipsis">{{ !!annexObj.file_way ? annexObj.file_way : annexObj.annex_name }}</div>
				<i class="el-icon-close pointer" @click="delCv"></i>
			</div>
		</div>
		<div class="resumeCv-bottom direction-between">
			<div class="space-between pointer" @click="navTo">
				<span>隐私设置</span>
				<span class="text-blue">设置</span>
			</div>
			<div class="space-between">
				<span>简历设置：</span>
				<span class="resumecv-text">{{
          getPrivacyData.resume_status == 1
            ? "公开"
            : getPrivacyData.resume_status == 2
            ? "仅投递企业"
            : "隐藏"
        }}</span>
			</div>
			<div class="space-between">
				<span>姓名保护：</span>
				<span class="resumecv-text">{{
          getPrivacyData.protect_name == 0 ? "未开启" : "开启"
        }}</span>
			</div>
			<div class="space-between">
				<span>手机保护：</span>
				<span class="resumecv-text">{{
          getPrivacyData.protect_phone == 0 ? "未开启" : "开启"
        }}</span>
			</div>
			<div class="space-between">
				<span>屏蔽公司：</span>
				<span class="resumecv-text">已屏蔽<span
						class="text-blue">{{ getPrivacyData.shield_company_num }}</span>家</span>
			</div>
		</div>
		<upload-resume ref="cvUpload" @uploadCv="uploadCv"></upload-resume>
		<appendix-cv ref="appendCv" @cvUploadClick="cvUploadClick"></appendix-cv>
	</div>
</template>

<script>
	import uploadResume from "./uploadResume.vue";
	import appendixCv from "./appendixCv.vue";
	import api from "../api/tool";
	export default {
		components: {
			uploadResume,
			appendixCv
		},
		props: {
			getPrivacyData: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				annexObj: null,
				annex_status: 0,
				msgText: "",
			};
		},

		created() {
			this.cvUploadClick();
		},

		mounted() {},

		methods: {
			delAnnexResume() {
				api.delAnnexResume().then(res => {
					if (res.code == 200) {
						this.cvUploadClick()
						this.$util.msg('删除成功')
					}
				})
			},
			delCv() {
				this.$confirm("此操作将永久删除该简历, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.delAnnexResume()
					})
			},
			navTo() {
				this.$router.push({
					path: "/privacy"
				});
			},
			cvShow() {
				this.$refs.cvUpload.show();
			},
			uploadCv() {
				this.$refs.appendCv.show();
			},
			cvUploadClick() {
				api.getAnnexResume().then((res) => {
					if (res.code == 200) {
						if (res.code == 200) {
							this.annex_status = res.data.annex_status;
							if (res.data.annex_status == 0 || !res.data.annex_status) {
								this.msgText = "你还未上传附件简历";
							} else if (res.data.annex_status == 1) {
								this.msgText = "简历审核中";
							} else if (res.data.annex_status == 3) {
								this.msgText = res.data.annex_rejection;
							} else if (res.data.annex_status == 2) {
								this.annexObj = res.data;
							}
						}
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.el-resumeCv {
		width: 317px;

		.resumeCv-heard {
			width: 100%;
			height: 6.875rem;
			background: #ffffff;
			padding: 1.5rem 1.25rem 1.25rem 1.25rem;
			align-items: flex-start;
			margin-bottom: 1.25rem;

			.title-cv-text {
				width: 100%;
				text-align: center;
				color: #333;
				font-weight: 550;
			}

			.resumeCv-heard-span {
				width: 100%;

				span:last-child {
					color: #faad14;
				}

				div {
					max-width: 80%;
				}
			}

			.resumeCv-heard-button {
				width: 100%;
				height: 2.125rem;
			}
		}

		.resumeCv-bottom {
			width: 100%;
			height: 12.5rem;
			background: #ffffff;
			padding: 0 1.3125rem;
			justify-content: space-evenly;

			div {
				width: 100%;
			}

			.resumecv-text {
				color: #9fa3af;
			}
		}
	}
</style>
