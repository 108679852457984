<template>
	<div class="my-resume">
		<logged-heard></logged-heard>
		<div class="my-resume-ceneter space-between">
			<div>
				<tabbar-model :typeId="2" :typeIndex="tabbarIndex" @toggleClick="toggleClick"></tabbar-model>
			</div>
			<div class="my-resume-ceneter-router one">
				<!-- 使用跟组件传参方式一样传递就行 -->
				<router-view></router-view>
			</div>
			<div>
				<resume-cv :getPrivacyData="getPrivacyData"></resume-cv>
			</div>
		</div>
		<feet-model></feet-model>
	</div>
</template>

<script>
	import loggedHeard from "../../components/loggedHeard.vue";
	import feetModel from "../../components/feet.vue";
	import tabbarModel from "../../components/tabbar.vue";
	import resumeCv from "../../components/resumeCv.vue";
	import api from "../../api/user";
	import {
		mapGetters,
		mapState
	} from "vuex";
	export default {
		components: {
			loggedHeard,
			feetModel,
			tabbarModel,
			resumeCv,
			...mapState(["userDetail"]),
		},
		data() {
			return {
				tabbarIndex: 1,
				// 隐私设置
				getPrivacyData: {}
			};
		},

		created() {
			this.tabbarIndex = this.$route.meta.index
			this.getPrivacy()
			this.getUserInfo()
		},
		//监听路由的变化
		watch: {
			$route(to, from) {
				this.tabbarIndex = to.meta.index
			},
		},
		mounted() {},

		methods: {
			toggleClick() {
				this.$emit('toggleClick');
			},
			// 获取用户信息
			getUserInfo() {
				api.userDetail().then((res) => {
					if (res.code == 200) {
						this.$store.commit("SET_USER", res.data);
						if (res.data.user_type == 2) this.$emit('toggleClick');
					}
				});
			},
			// 获取设置
			getPrivacy() {
				api.getPrivate().then((res) => {
					if (res.code == 200) {
						this.getPrivacyData = res.data
					}
				});
			}
		}
	}
</script>
<style lang='less' scoped>
	.my-resume {
		width: 100%;
		min-height: 100vh;
		background: #F6F6F8;

		.my-resume-ceneter {
			width: 100%;
			padding: 1.25rem 11.875rem 2.125rem 11.875rem;
			align-items: flex-start;

			.my-resume-ceneter-router {
				margin: 0 1.25rem;
			}
		}
	}
</style>
