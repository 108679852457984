<template>
	<!-- 上传简历的弹窗组件 -->
	<div>
		<div v-if="status">
			<div class="position-fixed top-0 left-0 right-0 bottom-0"
				style="background-color: rgba(0, 0, 0, 0.5); z-index: 999" @click.stop="hide"></div>
			<div class="el-upload-resume">
				<div class="row-end">
					<img src="../assets/forget/15.png" class="pointer close-img" alt="" @click="hide" />
				</div>
				<div class="resume-center">
					<div class="resume-center-img direction">
						<el-upload class="upload-demo" drag accept=".jpg,.png,.pdf,.doc,.docx" action=""
							:http-request="uploadMainImg" :show-file-list="false" :disabled="disabled">
							<img src="../assets/forget/16.png" alt="" />
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="font-lighter text-2">
								简历建议使用PDF文件，也支持DOC、DOCX、JPG、PNG格式
							</div>
							<div class="font-lighter text-3">文件大小不超过20M(一次只能上传一个)</div>
						</el-upload>
					</div>
					<div v-if="fileName" class="file-name-div pointer space-between"
						:class="fileNameShow ? 'file-active' : ''" @mouseenter="fileNameShow = true"
						@mouseleave="fileNameShow = false">
						<div class="file-left-width text-ellipsis ellip">
							<i class="el-icon-document"></i>
							<span>{{ fileName }}</span>
						</div>
						<i class="el-icon-close" v-if="fileNameShow" @click="deleteClick"></i>
					</div>
					<div class="el-upload-button bg-blue text-white font-normal flex-center pointer" @click="subResume">
						上传简历
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "../api/tool";
	export default {
		components: {},

		data() {
			return {
				status: false,
				disabled: false,
				file: null,
				fileName: "",
				fileNameShow: false,
			};
		},

		created() {},

		mounted() {},

		methods: {
			show() {
				this.status = true;
			},
			hide() {
				this.status = false;
				this.file = null
				this.fileName = ''
				this.disabled = false;
			},
			deleteClick() {
				this.$confirm(`确定移除 ${this.fileName}？`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.disabled = false;
						this.fileName = "";
						this.file = null;
						this.$message({
							type: "success",
							message: "移除成功!",
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消",
						});
					});
			},
			// 上传附件简历
			uploadMainImg(file) {

				const isLt2M = file.file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error("上传文件大小不能超过 2MB!");
					return;
				}
				this.disabled = true;
				this.fileName = file.file.name;
				this.file = file.file;
			},
			subResume() {
				if (!this.file) {
					this.$util.msg("请选取文件", "error");
					return;
				}
				// 创建一个formData对象实例的方式
				const data = new FormData();
				data.append("iFile", this.file);
				api
					.uploadResume(data)
					.then((res) => {
						this.hide();
						this.$util.msg(res.msg);
						this.$emit('cvUploadClick')
					})
					.catch((err) => {
						this.$message({
							showClose: true,
							message: "上传失败",
							type: "error",
						});
					});
			},
		},
	};
</script>
<style lang="less" scoped>
	.el-upload-resume {
		width: 608px;
		height: 409px;
		background: #ffffff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1000;
		padding: 22px 22px 28px 33px;

		.close-img {
			width: 11px;
			height: 11px;
		}

		.resume-center {
			width: 100%;
			padding-right: 11px;
			margin-top: 11px;

			.resume-center-img {
				height: 250px;
				width: 100%;
				color: #485066;
				justify-content: space-evenly;

				.upload-demo {
					width: 100%;
				}

				img {
					width: 2.625rem;
					height: 3.0625rem;
				}

				.text-2 {
					color: #9fa3b0;
				}

				.text-3 {
					color: #fc703e;
				}

				/deep/.el-upload-dragger {
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					align-items: center;
					width: 542px;
				}

				/deep/.el-upload-list {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 1;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					white-space: normal;
				}

				/deep/.el-upload-list__item {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 1;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					white-space: normal;
				}
			}

			.file-name-div {
				width: 100%;
				font-size: 14px;
				color: #606266;
				overflow: hidden;

				.file-left-width {
					max-width: 90%;

				}

				span {
					display: inline-block;
					margin-left: 4px;
				}
			}

			.file-active {
				span {
					color: #409eff;
				}
			}

			.el-upload-button {
				margin-top: 30px;
				height: 34px;
				width: 100%;
			}
		}
	}
</style>
